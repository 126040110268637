.dashboard-main-card {
  display: grid;
  padding: 2rem 2rem;
  color: black;
}
.card-title h6 {
  color: black;
  font-weight: normal;
}
.dashboard-header-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5rem;
}
.dashbord-content {
  border-radius: 1rem;
  background-color: #fff;
  color: black;
}
.graph-container {
  margin-top: 5rem;
}
.graph-content {
  display: grid;
  grid-template-columns: 7fr 3fr;

  /* align-items: center; */
}

/*  this is for position of the  filter*/

.filter_position {
  position: absolute;
  left: 51%;
  top: 73px;
}
